import * as React from "react";
import {getImage} from "gatsby-plugin-image"
import {graphql, PageProps} from "gatsby";

import CleanDataParser from "../helpers/editorjs-parser";
import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import Block4Image8Text from "../components/blocks/block-4image-8text";
import Section from "../components/section";
import StepSection from "../components/step-section";
import BenefitsSliderServices from "../components/slider/benefits-slider-services";
import TemplateHead from "../components/template-head";
import SafeBlock from "../components/safe-block";

import "../styles/page-services.scss";

type DataProps = {
  strapiAdvisor: any,
  bespokePageData: any,
}

export default function DirectCremation({data: {strapiAdvisor, bespokePageData}}: PageProps<DataProps>) {
  const imageSimple = getImage(bespokePageData?.service?.simpleImage?.localFile)
  const advisor = strapiAdvisor

  const priceText = (
    <div className="price-text mt-2 text-white">
      {bespokePageData?.service?.priceText?.data?.priceText ? (
        JSON.parse(bespokePageData?.service?.priceText?.data?.priceText).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const textSimple = (
    <div className="">
      {bespokePageData?.service?.textSimple?.data?.textSimple ? (
        JSON.parse(bespokePageData?.service?.textSimple?.data?.textSimple).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const textHowItWorks = (
    <div className="">
      {bespokePageData?.service?.textHowItWorks?.data?.textHowItWorks ? (
        JSON.parse(bespokePageData?.service?.textHowItWorks?.data?.textHowItWorks).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--funeral pl-4 pr-4"
        bgImageClassName="hero-image hero-image--funeral"
        contentClassName="hero-content hero-content--funeral"
        altImages="Bespoke Funeral Packages Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {name: "Bespoke Funeral Packages", slug: "/bespoke-funeral-packages/"},
        ]}
      >
        <HeroContentHeading
          headingTitle={bespokePageData?.service?.title}
          headingContent={bespokePageData?.service?.subtitle}
          advisorData={advisor}
          citeText=""
          safeBadge={true}
        />
      </Hero>
      <Section>
        <Block4Image8Text
          rowClass="row no-gutters d-flex flex-column flex-md-row "
          col1Class="col-12 col-md-4 col-top-left"
          col2Class="col-12 col-md-8 col-bottom-right bg-white pl-4 pl-md-5 pr-4 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5 col-text"
          imageOptimized={imageSimple}
          h2={bespokePageData?.service?.titleSimple}
          text={textSimple}

        />
      </Section>
      <BenefitsSliderServices
        benefitsData={bespokePageData?.service?.slider}
        sliderClass="bg-deepblue pl-0 pr-0 pl-sm-4 pr-sm-4 pt-5 pt-md-0 pb-0 service-benefits"
      />
      <Section
        sectionClass={"section-container safe-section pl-4 pr-4 pb-0 pt-4 pt-lg-2 pb-lg-2"}
      >
        <SafeBlock/>
      </Section>
      <StepSection
        stepData={bespokePageData?.service?.guidance}
        stepDataTitle2={bespokePageData?.service?.titleHowItWorks}
        stepDataSubtitle2={textHowItWorks}
        stepDataCta={"Begin planning a funeral"}
      />
      <TrustpilotCarousel/>
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title="Bespoke Funeral Packages - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/bespoke-funeral-packages/"
      alternate="https://fenixfuneral.co.uk/bespoke-funeral-packages/"
      canonical="https://fenixfuneral.co.uk/bespoke-funeral-packages/"
      og={{
        url: "https://fenixfuneral.co.uk/bespoke-funeral-packages/",
        title: "Bespoke Funeral Packages - Fenix Funeral Directors",
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "position": "1",
          "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"},
          {
          "@type": "ListItem",
          "position": "2",
          "name": "Bespoke Funeral Packages","item": "https://fenixfuneral.co.uk/bespoke-funeral-packages/"
          }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        bespokePageData: strapiServiceBespokeFuneralPackage {
            service {
                title
                subtitle
                titleSimple
                textSimple {
                    data {
                        textSimple
                    }
                }
                simpleImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                titleHowItWorks
                textHowItWorks {
                    data {
                        textHowItWorks
                    }
                }
                guidance {
                    titleNumber
                    textGuide {
                        data {
                            textGuide
                        }
                    }
                }
                slider {
                    title
                    textMain
                    icon {
                        url
                    }
                }
            }
        }
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400,
                            height: 400,
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
    }
`;